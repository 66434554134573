import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import { dataAmigavel } from "../util/General";

export default function CardAnalise({analise}){
    const link = analise.panel === 'Motion' ? `/motion/${analise.uid}` : `/analisar/${analise.uid}`;

    return (
        <Card elevation={6}>
            <CardActionArea href={link} >
                <CardMedia
                    component="img"
                    height="194"
                    image={analise.capa}
                    loading="lazy"
                />
                <CardContent>
                    <Typography gutterBottom variant="h6">
                        {analise.title}
                    </Typography>
                    <Typography color="text.secondary">
                        {analise.panel}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        {dataAmigavel(analise.lastUpdate)}
                    </Typography>                    
                </CardContent>
            </CardActionArea>
        </Card>
    );

}