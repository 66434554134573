import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Alert, Card, CardContent, IconButton, InputAdornment, Pagination, TextField } from '@mui/material';

import AppBarDashboard from "./AppBarDashboard";
import NewAnalysisDialog from '../components/NewAnalysisDialog';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { removeAccents } from '../lib/util/util';

import CardAnalise from './CardAnalise';
import { get, getDatabase, ref, update } from 'firebase/database';
import { useAuth } from '../context/AuthContext';
import SubscribeDialog from '../components/SubscribeDialog';
import GenericDialog from '../components/GenericDialog';
import { Search } from '@mui/icons-material';

const Dashboard = (props) => {

    const { user, permission, sendVerification } = useAuth();
    const auth = getAuth();
    const navigate = useNavigate();
    const [analises, setAnalises] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [newAnalysisDialogOpen, setNewAnalysisDialogOpen] = useState(false);
    const [openSubscribeDialog,setOpenSubscribeDialog] = useState(false);
    const [openDialogValidation,setOpenDialogValidation] = useState(false);

    const compararDatas = (objetoA, objetoB) => {
        const dataA = new Date(objetoA.data);
        const dataB = new Date(objetoB.data);
        return dataB - dataA;
    };
    
    useEffect(()=>{
        
        onAuthStateChanged(auth, (user) => {
            if(user){                
                setCurrentUser(user);
            }else{
                navigate('/login');
            }
        });

        if(currentUser){
            const dbRef = ref(getDatabase(),`/users/${currentUser.uid}/analises`);
            get(dbRef).then((snapshot) => {
                if(snapshot.val()){
                    const dados = Object.values(snapshot.val());
                    const dadosOrdenados = dados.sort(compararDatas);
                    setAnalises(dadosOrdenados);
                    setFilteredAnalises(dadosOrdenados);
                }
            });

            if(!permission && currentUser.emailVerified){
                const dbRefNewsletter = ref(getDatabase(),`/users/${currentUser.uid}/firstAccess`);
                get(dbRefNewsletter).then(async(snapshot) => {
                    if(!snapshot.val()){
                        update(dbRefNewsletter, {newsletter: true});
                        const name = currentUser.displayName;
                        const email = currentUser.email;
                        const status = 4;
                        await fetch('https://us-central1-newsletter-8dce2.cloudfunctions.net/newsletter/subscribe', {
                            method: 'POST',
                            timeout: 5000,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ name, email, status })
                        })
                    }
                });
            }
        }

        return ()=>{};

    },[currentUser,auth,navigate]);

    const handleNewAnalysisDialogOpen = () => {
        if(!user.emailVerified && !permission){
            setOpenDialogValidation(true);
        }else if(analises.length >= 5 && !permission){
            setOpenSubscribeDialog(true);
        }else{
            setNewAnalysisDialogOpen(true);
        }        
    }

    // SISTEMA DE BUSCA - INICIO ----------------------------------
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredAnalises, setFilteredAnalises] = useState(analises);

    const handleSearch = (term) => {
        const normalizedTerm = removeAccents(term.toLowerCase());
        const filtered = analises.filter((analise) => 
            removeAccents(analise.title.toLowerCase()).includes(normalizedTerm)
        );
        setFilteredAnalises(filtered);
    }
    const handleChangeSearchTerm = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        handleSearch(term);
    }
    // SISTEMA DE BUSCA - FIM ----------------------------------


    // SISTEMA DE PAGINAÇÃO - INÍCIO ----------------------------------
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(14);
    
    const handleChangePage = (event, value) => {
        setPage(value);
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top
    }
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedAnalises = filteredAnalises.slice(startIndex, endIndex);
    // SISTEMA DE PAGINAÇÃO - FIM ----------------------------------


    //header para que as imagens do storage funcionem - inicio ----------------------------------
	useEffect(() => {	
		let embedderPolicyMeta = document.querySelector('meta[name="Cross-Origin-Embedder-Policy"]');
		let openerPolicyMeta = document.querySelector('meta[name="Cross-Origin-Opener-Policy"]');
		if (embedderPolicyMeta) {		  
            document.querySelector('meta[name="Cross-Origin-Embedder-Policy"]').removeAttribute('content');
            console.log("removeu");
		}
		if (openerPolicyMeta) {
            document.querySelector('meta[name="Cross-Origin-Opener-Policy"]').removeAttribute('content');
            console.log("removeu 2");
		}
    }, []);
    //header para que as imagens do storage funcionem - fim ----------------------------------

    return(
		<Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', }}>
			<AppBarDashboard />
            
            {currentUser && (
                <Fade in={true}>
                    <Container component="main" maxWidth="false" sx={{my:8, p:3}}>
                        
                        <Grid container spacing={3}>

                            <Grid item xs={6} sx={{display:'flex'}}>
                                <Typography variant="h5">Suas análises</Typography>
                            </Grid>

                            <Grid item xs={6} sx={{display:'flex', justifyContent:'flex-end'}}>
                                <TextField 
                                    label="Busca"
                                    size="small"
                                    value={searchTerm}
                                    onChange={handleChangeSearchTerm}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    size="small"
                                                >
                                                    <Search fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>

                            {user && !user.emailVerified && !permission && (
                                <Grid item xs={12}>
                                    <Alert 
                                        severity="info" 
                                        variant="filled"
                                        action={
                                            <Button color="inherit" size="small" onClick={()=>{sendVerification(user)}}>
                                                Reenviar
                                            </Button>
                                        }
                                    >
                                        Você ainda não confirmou seu e-mail. Acesse o link enviado para: {user.email}
                                    </Alert>
                                </Grid>
                            )}

                            {!window.electron && (
                                <Grid item xs={12}>
                                    <Alert 
                                        severity="info" 
                                        variant="outlined"
                                        action={
                                            <Button variant="contained" color="inherit" size="small" onClick={()=>{navigate('/download')}}>
                                                download
                                            </Button>
                                        }
                                    >
                                        Utilize a versão Desktop e faça exportações 25x mais rápidas.
                                    </Alert>
                                </Grid>
                            )}

                            <Grid item xs={12} sm={6} lg={4}>
                                <Card elevation={6} sx={{minHeight: '300px', height:'100%',display:'flex'}}>
                                    
                                        <CardContent sx={{height:'100%', width:'100%',display:'flex', flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                                            <div>
                                            <Button 
                                                disableElevation
                                                size="large" 
                                                variant="contained" 
                                                onClick={handleNewAnalysisDialogOpen}
                                                >
                                                Nova Análise
                                            </Button>

                                            </div>
                                            {!analises &&(
                                            
                                                <Button 
                                                    sx={{mt:4}}
                                                    size="large" 
                                                    variant="outlined" 
                                                    onClick={()=>{window.location.href = '/tutorial';}}
                                                >
                                                    Ver Tutorial
                                                </Button>                                                
                                            )}
                                        </CardContent>
                                    
                                </Card>
                            </Grid>

                            {paginatedAnalises && (
                                paginatedAnalises.map((analise,index)=>(
                                    <Grid item xs={12} sm={6} lg={4} key={index}>
                                        <CardAnalise analise={analise} />
                                    </Grid>
                                ))
                            )}

                            <Grid item xs={12} >
                                <Pagination
                                    sx={{width:'100%',display:'flex', justifyContent:'center'}}
                                    count={Math.ceil(filteredAnalises.length / itemsPerPage)} 
                                    page={page} 
                                    onChange={handleChangePage} 
                                />
                            </Grid>
                        
                        </Grid>
                        

                        <NewAnalysisDialog userUid={currentUser.uid} setNewAnalysisDialogOpen={setNewAnalysisDialogOpen} newAnalysisDialogOpen={newAnalysisDialogOpen} />
                        <GenericDialog 
                            openDialog={openDialogValidation}
                            setOpenDialog={setOpenDialogValidation}
                            title={"Confirme seu e-mail"}
                            message={"Para continuar você precisa confirmar o seu e-mail, acesse o link enviado para sua caixa de entrada."}
                        />

                    </Container>

                </Fade>

            )}
            <SubscribeDialog setOpenSubscribeDialog={setOpenSubscribeDialog} openSubscribeDialog={openSubscribeDialog} />
        </Box>
    );

}

export default Dashboard;